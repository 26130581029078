import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFlashCardsList() {
  // Use toast
  const toast = useToast()

  const refFlashCardsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'front_en', sortable: true },
    // { key: 'front_ar', sortable: false },
    { key: 'back_en', sortable: true },
    // { key: 'back_ar', sortable: false },
    { key: 'course', sortable: false },
    { key: 'category', sortable: false },
    { key: 'sub_category', sortable: false },
    { key: 'created_by', sortable: false },
    { key: 'created_at', sortable: false },
    { key: 'is_active', sortable: false },
    { key: 'is_free_content', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalFlashCards = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const courseFilter = ref(null)
  const categoryFilter = ref(null)
  const subCategoryFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refFlashCardsListTable.value ? refFlashCardsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFlashCards.value,
    }
  })

  const refetchData = () => {
    refFlashCardsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, courseFilter, categoryFilter, subCategoryFilter], () => {
    refetchData()
  })

  const fetchFlashCards = (ctx, callback) => {
    store
      .dispatch('flash-cards/fetchFlashCards', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        course: courseFilter.value,
        category: categoryFilter.value,
        sub_category: subCategoryFilter.value,
      })
      .then(response => {
        const { flashCards, total } = response.data

        callback(flashCards)
        totalFlashCards.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchCourseFlashCards = (ctx, callback) => {
    store
        .dispatch('flash-cards/fetchFlashCards', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          course: courseFilter.value,
          category: categoryFilter.value,
          sub_category: subCategoryFilter.value,
        })
        .then(response => {
          const { flashCards, total } = response.data

          callback(flashCards)
          totalFlashCards.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchFlashCards,
    fetchCourseFlashCards,
    tableColumns,
    perPage,
    currentPage,
    totalFlashCards,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFlashCardsListTable,

    refetchData,

    courseFilter,
    categoryFilter,
    subCategoryFilter,
  }
}
