import { render, staticRenderFns } from "./FlashCardsList.vue?vue&type=template&id=9f7403b6&scoped=true"
import script from "./FlashCardsList.vue?vue&type=script&lang=js"
export * from "./FlashCardsList.vue?vue&type=script&lang=js"
import style0 from "./FlashCardsList.vue?vue&type=style&index=0&id=9f7403b6&prod&lang=scss&scoped=true"
import style1 from "./FlashCardsList.vue?vue&type=style&index=1&id=9f7403b6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f7403b6",
  null
  
)

export default component.exports